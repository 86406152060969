import React, { useState, useEffect, createRef, useContext } from "react";
import { useNavigate } from "react-router-dom";
import "survey-core/modern.min.css";
import * as Survey from "survey-react";
//Json for form Survey
import "./SurveyJS/Index";
import { FlowContext } from '../../../Providers/FlowProvider';

Survey.StylesManager.applyTheme("modern");
export const SurveyPagesData = [
	{
		name: "1. Soru",
		navigationTitle: "1",
		elements: [
			{
				type: "panel",
				name: "panel",
				elements: [
					{
						type: "metric",
						name: "metric",
						title: "Text",
						titleLocation: "hidden",
					},
				],
			},
			{
				type: "image",
				name: "first_page_image",
				imageLink:
					"https://joy-leads.s3.eu-central-1.amazonaws.com/rs/saglikonemli/q1.png",
			},
			{
				type: "radiogroup",
				inputType: "radiogroup",
				name: "radiogroupquestions1",
				title: `1.${name}${name?.length ? "," : ""} Cinsiyetini bizimle paylaşabilir misin?`,
				hasNone: true,
				choices: ["Kadın", "Erkek", "Belirtmek istemiyorum"],
				titleLocation: "hidden",
			},
		],
	},
	{
		name: "2. Soru",
		navigationTitle: "2",
		/* 	navigationDescription: "overload - MUI", */
		elements: [
			{
				type: "image",
				name: "second_page_image",
				imageLink:
					"https://joy-leads.s3.eu-central-1.amazonaws.com/rs/saglikonemli/q2.png",
			},
			{
				type: "radiogroup",
				inputType: "radiogroup",
				name: "radiogroupquestions2",
				title: `2.${name}${name?.length ? "," : ""} hangi yaş aralığında olduğunu da öğrenebilir miyiz?`,
				hasNone: true,
				choices: [
					"18'den küçük",
					"18-24",
					"25-34",
					"35-44",
					"45-64",
					"65 üstü",
				],
				titleLocation: "hidden",
			},
		],
	},
	{
		name: "3. Soru",
		navigationTitle: "3",
		elements: [
			{
				type: "image",
				name: "third_page_image",
				imageLink:
					"https://joy-leads.s3.eu-central-1.amazonaws.com/rs/saglikonemli/q3.jpg",
			},
			{
				type: "radiogroup",
				inputType: "radiogroup",
				name: "radiogroupquestions3",
				title: `3. Artık testimize başlayabiliriz. Aşağıdaki ortamlardan hangisinde vakit geçirmeyi seversin?`,
				hasNone: true,
				choices: [
					"Arkadaşlarımla sosyal bir ortam vazgeçilmezimdir.",
					"Tabi ki spor yaparken",
					"Film/dizi izlerken iyiyimdir",
				],
				titleLocation: "hidden",
			},
		],
	},
	{
		name: "4. Soru",
		navigationTitle: "4",
		elements: [
			{
				type: "image",
				name: "fourth_page_image",
				imageLink:
					"https://joy-leads.s3.eu-central-1.amazonaws.com/rs/saglikonemli/q4.jpg",
			},
			{
				type: "radiogroup",
				inputType: "radiogroup",
				name: "radiogroupquestions4",
				title: `4.${name}${name?.length ? "," : ""} Gün içerisinde internette/sosyal medyada ne kadar zaman geçirsin?`,
				hasNone: true,
				choices: [
					"Ortalama 0-3 saat",
					"Benim sosyal medyam arkadaşlarım",
					"4 saat ve üstü",
				],
				titleLocation: "hidden",
			},
		],
	},
	{
		name: "5. Soru",
		navigationTitle: "5",
		elements: [
			{
				type: "image",
				name: "fifth_page_image",
				imageLink:
					"https://joy-leads.s3.eu-central-1.amazonaws.com/rs/saglikonemli/q5.jpg",
			},
			{
				type: "radiogroup",
				inputType: "radiogroup",
				name: "radiogroupquestions5",
				title: `5.${name}${name?.length ? "," : ""} Gün içerisinde en çok ne tür içecekler tüketirsin?`,
				hasNone: true,
				choices: [
					"Çay-kahve",
					"Soda-su",
					"Alkolle aram iyidir",
				],
				titleLocation: "hidden",
			},
		],
	},
	{
		name: "6. Soru",
		navigationTitle: "6",
		elements: [
			{
				type: "image",
				name: "sixth_page_image",
				imageLink:
					"https://joy-leads.s3.eu-central-1.amazonaws.com/rs/saglikonemli/q6.jpg",
			},
			{
				type: "radiogroup",
				inputType: "radiogroup",
				name: "radiogroupquestions6",
				title: `6. Peki ${name}${name?.length ? "," : ""}, Aşağıdakilerden hangisi senin yeme alışkanlığını gösterir?`,
				hasNone: true,
				choices: [
					"Her gün olmasa da hep aynı saatlere denk getirmeye çalışırım",
					"Her gün aynı saatte yemek yerim",
					" Ne zaman acıkırsam yerim",
				],
				titleLocation: "hidden",
			},
		],
	},
	{
		name: "7. Soru",
		navigationTitle: "7",
		elements: [
			{
				type: "image",
				name: "seventh_page_image",
				imageLink:
					"https://joy-leads.s3.eu-central-1.amazonaws.com/rs/saglikonemli/q7.jpg",
			},
			{
				type: "radiogroup",
				inputType: "radiogroup",
				name: "radiogroupquestions7",
				title: `7.${name}${name?.length ? "," : ""} Aşağıdakilerden hangisi günlük rutinlerin arasındadır?`,
				hasNone: true,
				choices: [
					"Cilt bakımımı yapmadan günü bitirmem",
					"Her gün minimum düzeyde hareket ederim",
					"Pek bir rutinim yok harekete geçmeyi düşünüyorum",
				],
				titleLocation: "hidden",
			},
		],
	},
	{
		name: "8. Soru",
		navigationTitle: "8",
		elements: [
			{
				type: "image",
				name: "eighth_page_image",
				imageLink:
					"https://joy-leads.s3.eu-central-1.amazonaws.com/rs/saglikonemli/q8.jpg",
			},
			{
				type: "radiogroup",
				inputType: "radiogroup",
				name: "radiogroupquestions8",
				title: `8.${name}${name?.length ? "," : ""} Spor yapıyorsan ya da yapmayı düşünüyorsan hangisi tercihin olur?`,
				hasNone: true,
				choices: [
					"Sahilde yürüyüş iyidir.",
					"Spor salonuna gidip o müziği duymadan motive olamıyorum.",
					"Evden çıkmadan hareket etmemi sağlayacak her programa uyarım.",
				],
				titleLocation: "hidden",
			},
		],
	},
	{
		name: "9. Soru",
		navigationTitle: "9",
		elements: [
			{
				type: "image",
				name: "ninth_page_image",
				imageLink:
					"https://joy-leads.s3.eu-central-1.amazonaws.com/rs/saglikonemli/q9.jpg",
			},
			{
				type: "radiogroup",
				inputType: "radiogroup",
				name: "radiogroupquestions9",
				title: `9.${name}${name?.length ? "," : ""}  Diyelim ki kendini ruhen iyi hissetmiyorsun, aşağıdakilerden hangisine başvurursun?`,
				hasNone: true,
				choices: [
					"Hemen arkadaşlarıma koşarım.",
					"Meditasyon yaparım tabi ki",
					"Bir şeyler izleyip kafamı dağıtırım dizi/film/spor her şey olur)",
				],
				titleLocation: "hidden",
			},
		],
	},
	{
		name: "10. Soru",
		navigationTitle: "10",
		elements: [
			{
				type: "image",
				name: "tenth_page_image",
				imageLink:
					"https://joy-leads.s3.eu-central-1.amazonaws.com/rs/saglikonemli/q10.jpg",
			},
			{
				type: "radiogroup",
				inputType: "radiogroup",
				name: "radiogroupquestions10",
				title: `10.${name}${name?.length ? "," : ""}  Okuldan/işten eve geldin, markete gitmen gerektiği gerçeğiyle yüzleştin. Markete ne ile gidersin?`,
				hasNone: true,
				choices: [
					"Arabayla giderim, gün içinde çok yoruldum zaten.",
					"Yürüyerek giderim, e fazladan spor sayılır",
					"Niye gideyim ki, mobil uygulamalar bunun için var",
				],
				titleLocation: "hidden",
			},
		],
	}
]
export default function SurveyJS() {
	const { flowIndex } = useContext(FlowContext);

	const surveyRef = createRef();
	const navigate = useNavigate();
	const [name, setName] = useState("");
	let choiceCounts = {
		1: 0,
		2: 0,
		3: 0,
	};

	const surveyModel = new Survey.Model({
		pages: SurveyPagesData,
		showProgressBar: "top",
		showNavigationButtons: "none",
		goNextPageAutomatic: true,
		allowCompleteSurveyAutomatic: true,
		progressBarType: "buttons",
	});

	const makeNextButtonClick = () => {
		var element = document.getElementsByClassName(
			"sv_progress-buttons__image-button-right"
		)[0]; //grab the element
		element.click();
	};

	const storeChoices = (question) => {
		question.question.choices.map((choice, index) => {
			if (choice.selected) {
				const temp = {
					...choiceCounts,
				};

				if (index < 3) {
					temp[index + 1] = temp[index + 1] + 1;

					choiceCounts = {
						...temp,
					};
				}
			}
		});
	};

	const setChoices = () => {
		const choiceCountsString = JSON.stringify(choiceCounts);

		localStorage.setItem("choiceCounts", choiceCountsString);
	};

	const generateNavigateCompleteSurvey = (gender) => {
		if (flowIndex === 0) {
			navigate(`/offers/${gender}?fIndex=${flowIndex}`, { replace: true });
		}
		else {
			navigate('/final' + '?fIndex=' + flowIndex);
		}
	}

	const completeSurvey = (data) => {
		const results = JSON.stringify(data);
		localStorage.setItem("answers", results);
		localStorage.setItem("gender", data === "Kadın" ? 0 : 1);

		const gender = data === "Kadın" ? 0 : 1;
		generateNavigateCompleteSurvey(gender);
	};


	const removeNameInput = () => {
		let toRemoveEl = document?.getElementsByClassName('nameInput')[0];
		let toRemoveEl2 = document?.getElementsByClassName('nameInput')[1];
		const parentElement = toRemoveEl?.parentNode;
		parentElement?.removeChild(toRemoveEl);
		parentElement?.removeChild(toRemoveEl2);
	}

	surveyModel.focusFirstQuestionAutomatic = true;


	surveyModel.onValueChanging.add((survey, question) => {
		const currentQuestion = survey.currentPageNo + 1;
		if (currentQuestion >= 1) {
			removeNameInput();
		}
		if (currentQuestion > 2) {
			storeChoices(question);
		}

		if (currentQuestion === 10) {
			setTimeout(() => {
				setChoices();
				surveyModel.doComplete();
			}, 200);
		}

		makeNextButtonClick();

		setTimeout(() => {
			surveyModel.nextPage();

			setTimeout(() => {
				window.scrollTo({
					left: 0,
					top: document.body.scrollHeight,
					behavior: "smooth",
				});
			}, 250);
		}, 300);
	});

	surveyModel.onComplete.add((sender) => {
		completeSurvey(sender.data);
	});

	surveyModel.completedHtml = "<div></div>";


	useEffect(() => {
		const storedName = localStorage.getItem('name');
		if (storedName) {
			setName(storedName);
		}

		let el = document.getElementById('sp_100')
		if (el && surveyRef.current.survey.currentPageNo === 0 && flowIndex !== 0) {
			const labelElement = document.createElement('label');
			labelElement.htmlFor = 'inputElement';
			labelElement.textContent = 'Adınız Soyadınız';
			labelElement.className = "nameInput nameInputLabel";
			const inputElement = document.createElement('input');
			inputElement.type = 'text';
			inputElement.placeholder = 'Lütfen adınızı soyadınızı giriniz';
			inputElement.className = "nameInput";
			inputElement.maxLength = 30;
			inputElement.value = storedName ? storedName : '';
			inputElement.oninput = (event) => {
				setName(event.target.value)
				localStorage.setItem("name", event?.target?.value);
			};
			const firstChild = el.firstChild;
			el.insertBefore(labelElement, firstChild);
			el.insertBefore(inputElement, firstChild);
		}
	}, [surveyRef])


	// useEffect(() => {
	// 	let titleEl = document.getElementsByClassName('title')[0];
	// 	if (titleEl) {
	// 		titleEl.textContent = name + (name?.length > 0 ? ", " : "") + titleEl.textContent;
	// 	}
	// }, [name])
	return (
		<div id="form_survey">
			<Survey.Survey ref={surveyRef} model={surveyModel} />
		</div>
	);
}
