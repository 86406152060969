import React, { useEffect, useRef, useContext } from "react";
import { Link } from "react-router-dom";
import Modal from "react-modal";
import { useNavigate } from 'react-router-dom';

import "./HomePage.scss";
import { FlowContext } from '../Providers/FlowProvider';

export default function HomePage() {
	const navigate = useNavigate();
	const { flowIndex } = useContext(FlowContext);

	useEffect(() => {
		window.scrollTo(0, 0);
		const iframe = document.getElementsByTagName("iframe")[0];

		if (iframe) {
			iframe.style.display = "none";
		}
		setTimeout(() => {
			const iframe = document.getElementsByTagName("iframe")[0];
			if (iframe) {
				setTimeout(() => {
					iframe.style.display = "none";
				}, 500);
			}
		}, 50);
		return () => {
			if (iframe) {
				iframe.style.display = "block";
			}
		};
	}, []);

	const handleInfoClick = () => {
		ref.current?.scrollIntoView({
			behavior: "smooth",
			block: "start",
			inline: "center",
		});
	};

	const handleTestClick = () => {
		testRef.current?.scrollIntoView({
			behavior: "smooth",
			block: "start",
			inline: "center",
		});
	};

	const customStyles = {
		content: {
			top: "50%",
			left: "50%",
			right: "auto",
			bottom: "auto",
			marginRight: "-50%",
			borderRadius: "8px",
			width: "40%",
			transform: "translate(-50%, -50%)",
		},
	};

	const [conditionsIsOpen, setConditionsIsOpen] = React.useState(false);
	const [privacyIsOpen, setPrivacyIsOpen] = React.useState(false);
	function openConditionsModal() {
		setConditionsIsOpen(true);
	}
	function closeConditionsModal() {
		setConditionsIsOpen(false);
	}
	function openPrivacyModal() {
		setPrivacyIsOpen(true);
	}
	function closePrivacyModal() {
		setPrivacyIsOpen(false);
	}
	const ref = useRef(null);
	const testRef = useRef(null);


	const generateSurveyStartPath = () => {
		if (flowIndex === 0) {
			navigate('/final?fIndex=' + flowIndex)
		}
		else {
			navigate('/survey?fIndex=' + flowIndex)
		}
	}

	return (
		<div className="homePage">
			<header>
				<div className="logoContainer" onClick={navigate('/', { replace: true })}>
					<img src="https://joy-leads.s3.eu-central-1.amazonaws.com/rs/saglikonemli/header-icon.png" />
					<span>İyi Yaşam Analizim</span>
				</div>
			</header>
			<div className="heroContainer">
				<div className="title">Kaliteli Yaşam Yolculuğunuzda<br />Kahraman Olun<br /></div>
				<div className="description">
					<br /><br />
				</div>

				<div className="buttons">
					<Link onClick={generateSurveyStartPath()} className="bannerStartSurvey"  >
						Testi Çöz
					</Link>
				</div>
			</div>
			<div ref={ref} className="infoContainer">
				<span className="title">Hoş Geldin! :)</span>
				<span className="description">
				İyi bir yaşam sürmek için alışkanlıkların ne kadar yeterli dersin? Gün içinde ruhuna ve bedenine yeterli ilgiyi gösteriyor musun?<br /><br />
				</span>
				<span className="description">
				Hayat tarzımız yaşam kalitemizi ortaya çıkartır. Nasıl bir yaşam tarzına sahip olduğunu öğrenmek için haydi teste başlayalım! :)<br /><br />
				</span>
			</div>
			
			<div className="cardContainer">
				<div className="card">
					<div className="cardBadge">1</div>
					<span className="cardText">
						Aşağıdaki formu eksiksiz doldur,
					</span>
				</div>

				<div className="card">
					<div className="cardBadge">2</div>
					<span className="cardText">
						Test sorularını çözüp teklif seç,
					</span>
				</div>

				<div className="card">
					<div className="cardBadge">3</div>
					<span className="cardText">
						Test sonucun e-postana gelsin!
					</span>
				</div>
			</div>

			<div className="startContainer">
				<img
					src="https://joy-leads.s3.eu-central-1.amazonaws.com/rs/arrow.png"
					className="arrowImage"
				/>
				<Link onClick={generateSurveyStartPath()} className="startSurvey">
					Teste Başla
				</Link>

				<span className="legalText">
					* "İyi Yaşam Analizim" tamamen ücretsizdir ve eğlence amaçlı yapılmıştır.
				</span>
			</div>

			<Modal
				isOpen={conditionsIsOpen}
				onRequestClose={closeConditionsModal}
				style={customStyles}
				contentLabel="condıtıons Modal"
			>
				<div className="conditionsModal">
					<div className="titleContainer">
						<span className="title">Şartlar ve Koşullar</span>
						<img
							src="https://joy-leads.s3.eu-central-1.amazonaws.com/rs/close-icon.svg"
							className="closeButton"
							onClick={closeConditionsModal}
						></img>
					</div>
					<div className="contentContainer">
						<span className="smallTitle">
							ReklamStore Hizmet Şartları
						</span>
						<span className="text">
							REKLAMSTORE KULLANICISI OLARAK, AŞAĞIDAKİ
							REKLAMSTORE HİZMET ŞARTLARINI ANLADIĞINIZ ÖNEMLİDİR.
							WEB SİTEMİZİN DEVAMLI KULLANIMI, REKLAMSTORE.COM
							TARAFINDAN BELİRLENEN BU ŞARTLARI OKUDUĞUNUZU VE
							KABUL ETTİĞİNİZİ GÖSTERİR.
						</span>
						<span className="text">
							ReklamStore Inc., bu web sitesini işletmektedir. Bu
							Hizmet Şartları, bu siteyi kullanımınız süresince
							önceden bildirim yapılmaksızın düzenlenebilir.
							ReklamStore.com'un devam eden kullanımı, yeni Hizmet
							Şartlarına uymayı kabul ettiğinizi doğrular. Lütfen
							ReklamStore ile yapılan herhangi başka bir
							anlaşmanın koşulları ve şartları da bu Hizmet
							Şartlarına ek olarak geçerlidir.
						</span>

						<span className="smallTitle">
							ReklamStore Gizlilik Politikası
						</span>

						<span className="text">
							ReklamStore Gizlilik Politikası hakkında herhangi
							bir sorunuz veya endişeniz varsa, ReklamStore.com'u
							ziyaret ederek politikamızı inceleyebilirsiniz.
							Politika, kullanıcılardan hangi verileri neden ve
							nasıl topladığımızı ve bu verileri nasıl
							kullandığımızı açıklar. Gizliliğiniz her zaman
							ReklamStore için en büyük önem taşır.
						</span>

						<span className="smallTitle">
							ReklamStore Telif Hakkı Koruması
						</span>

						<span className="text">
							ReklamStore.com telif hakkı koruması altındadır. Bu
							koruma, ReklamStore logosunu, metin türünü,
							grafikleri, web sitesi tasarımını, dosyaları ve
							bunların herhangi bir düzenlemesini içerir, ancak
							bunlarla sınırlı değildir. Bu politikada
							belirtilmediği sürece, ReklamStore materyali,
							önceden yazılı izin almadan kopyalanamaz, yeniden
							yayınlanamaz, çoğaltılamaz, dağıtılamaz,
							görüntülenemez, iletilmez, indirilemez veya herhangi
							bir şekilde veya herhangi bir araçla, ancak bunlarla
							sınırlı olmamak üzere, fotokopi, elektronik, kayıt
							veya mekanik yöntemlerle kullanılamaz. Bu web
							sitesindeki materyalleri bilgi bulma ve/veya ticari
							olmayan amaçlarla kullanmak için izin alabilirsiniz.
							İzin verilmesi durumunda, bu materyalleri
							çoğaltmayacağınızı, dağıtmayacağınızı veya
							kopyalamayacağınızı, bu materyalleri
							değiştirmeyeceğinizi, telif hakkıyla korunan
							materyallerden herhangi birini silmeyeceğinizi veya
							düzenlemeyeceğinizi taahhüt edersiniz.
						</span>

						<span className="smallTitle">
							ReklamStore Ticari Markaları
						</span>

						<span className="text">
							ReklamStore, tescilli bir ticari markadır ve bu
							nedenle ticari marka yasaları tarafından
							korunmaktadır. ReklamStore'un ticari markalı
							materyalinin herhangi bir kısmını kullanmak, taklit
							etmek veya kopyalamak, önceden yazılı izin almadan
							ReklamStore'dan yapılmış olmalıdır. Düğme simgeleri,
							betikler, sayfa başlıkları ve özel grafikler,
							ReklamStore'un ticari markaları olarak kabul edilir
							ve dolayısıyla korunur. ReklamStore, dış varlıklar
							tarafından sahip olunan ticari markaları da
							gösterebilir. Bu ticari markalar ReklamStore
							tarafından korunmayabilir, ancak ticari marka
							sahipleri tarafından korunur. ReklamStore.com'un
							kullanımı kişisel, ticari olmayan kullanımla sınırlı
							olmalıdır. ReklamStore'un herhangi bir yönünü
							yeniden satmak yasaktır. Lütfen ReklamStore
							sitelerinde herhangi bir veri toplama veya çekme
							yöntemi kullanmaktan kaçının.
						</span>

						<span className="smallTitle">
							ReklamStore Web Sitesi Bağlantı Şartları
						</span>

						<span className="text">
							ReklamStore'a bir bağlantı oluşturmanıza izin
							verilebilir; ancak, ReklamStore'u yanıltıcı, yanlış,
							aşağılayıcı veya iftira niteliğinde bir şekilde
							aktaramazsınız. Tescilli ticari markaları kullanmak
							için materyalin kullanımından önce ReklamStore'dan
							yazılı izin almanız gerekmektedir.
						</span>

						<span className="text">
							ReklamStore.com dışında bir bağlantıyı takip eden
							herhangi bir kullanıcı artık korumamız altında
							değildir. ReklamStore, bağlantılı web sitelerinde
							bulunabilecek uygulamalardan veya içerikten sorumlu
							tutulamaz. Web sitemizden ayrılmak için bir
							bağlantıyı takip etmenin kendi riskinizde olduğunu
							unutmayın.
						</span>

						<span className="smallTitle">
							ReklamStore Web Sitesi Kullanımı
						</span>

						<span className="text">
							ReklamStore olarak, web sitemizdeki kullanıcılara
							güven duymayı tercih ediyoruz. Sizden saygılı ve
							ahlaki sınırlar içinde davranmanızı bekliyoruz. Bu
							noktadan itibaren, bu politikada belirlenen
							kurallara uymayı ve ahlaki olarak hareket etmeyi
							kabul ettiğinizi kabul edersiniz. Asla web sitemizin
							kullanıcılarının güvenliğini veya ReklamStore'u
							bozmamalısınız. ReklamStore'u veya hizmetlerimizi
							kötüye kullanmamalısınız. Sistem kaynaklarını asla
							kötüye kullanmayacak, hesaplara müdahale
							etmeyeceksiniz veya ReklamStore sunucularını veya
							ReklamStore veya bağlı kuruluşlarımız aracılığıyla
							erişilebilen herhangi bir ağı engellemeyeceksiniz.
							ReklamStore kullanıcılarının keyfini bozmamalısınız.
							Her zaman ReklamStore'un sitelerine erişimle ilgili
							kısıtlamalara uymalısınız. En önemlisi, ReklamStore
							kullanımıyla uyumlu olan tüm yasalara uymayı kabul
							edersiniz. Bunlar uluslararası, ulusal, eyalet veya
							yerel düzenlemeler olabilir. ReklamStore'da reklam
							veren işletmelerden veya sundukları hizmetlerin veya
							ürünlerin kalitesinden sorumlu olmadığımızı kabul
							edersiniz.
						</span>

						<span className="smallTitle">
							Telif Hakkı veya Ticari Marka İhtilaflarında
						</span>

						<span className="text">
							ReklamStore, telif hakkı veya ticari markalı
							materyalinizi korumak istemektedir. Digital
							Millennium Copyright Act (DMCA) gerekliliklerine
							uyarız. ReklamStore'da bulunan herhangi bir iddia
							edilen telif hakkı/ticari marka ihlali, şu bilgileri
							telif hakkı temsilcimize göndererek dikkatimize
							getirilebilir:
						</span>

						<span className="text">
							Münhasır telif hakkı sahibinin yetkisine sahip olan
							kişi, imzasını eklemelidir. Ticari marka tescil
							numaranızı ve tescilli ticari marka kanıtınızı
							ekleyin. Sorunlu içeriği bize gösterin. E-posta
							adresinizi, yazışma almak istediğiniz telefon
							numarasını ve güncel adresinizi ekleyin. Haklarının
							ihlal edildiğini düşündüğünüz materyali açıklayan
							orijinal bir beyan ekleyin. Gönderilen bilgilerin
							doğru, yanıltıcı olmadığına ve bu şikayette bulunma
							yetkinizin olduğuna dair bir ifadeyi belirtin.
							ReklamStore telif hakkı temsilcisiyle iletişim
							kurmak için info@reklamstore.com adresine e-posta
							gönderin.
						</span>

						<span className="smallTitle">
							ReklamStore İndemnifikasyon Anlaşması
						</span>

						<span className="text">
							Lütfen dikkat edin, ReklamStore Hizmet Şartlarını
							ihlal ettiğinizde, başka bir kullanıcının haklarını
							ihlal ettiğinizde veya ReklamStore.com'u başka bir
							tarafın talep, iddia, dava veya tazminat isteğiyle
							sonuçlanan bir davranış için kullanmanız durumunda,
							ReklamStore'u tazmin edeceğinizi kabul edersiniz.
							İndemnifikasyon, ReklamStore'u sorumlu
							tutmayacağınız anlamına gelir.
						</span>

						<span className="smallTitle">
							Tarafınızdan Gönderilen Materyaller
						</span>

						<span className="text">
							ReklamStore, e-posta veya diğer gönderim süreci
							aracılığıyla sağladığınız sorularınızın,
							önerilerinizin, gönderdiğiniz yorumların, verdiğiniz
							fikirlerin, paylaşılan çizimlerin, oluşturulan
							planların, sunulan orijinal materyallerin veya diğer
							verilerin kapsamlı gizliliğini garanti edemez ve bu,
							web sitemize yapabileceğiniz diğer olası yayınları
							da içerir. Gönderildikten sonra, bu materyaller
							ReklamStore'un mülkiyeti haline gelecek ve yukarıda
							bahsedilen materyallere ilişkin haklarınızı
							kaybedeceksiniz. ReklamStore bu materyalleri
							bildirim veya ödeme yapmadan herhangi bir amaç için
							kullanabilir.
						</span>

						<span className="smallTitle">
							ReklamStore Garanti Reddi
						</span>

						<span className="text">
							REKLAMSTORE KULLANICILARI, BU WEB SİTESİNİ VE
							İÇERİĞİNİ, MATERYALLERİNİ VE ÜRÜNLERİNİN "OLDUĞU
							GİBİ" VE MEVCUT OLDUĞU ŞEKİLDE SUNULDUĞUNU ANLAR VE
							KABUL EDER. REKLAMSTORE'U KULLANDIĞINIZDA KENDİ
							RİSKİNİZDEDİR. REKLAMSTORE, BAŞLIK HAKLARI VEYA
							DİĞER İMA EDİLEN GARANTİLERİ REDDEDER. REKLAMSTORE
							VEYA REKLAMSTORE İLE İLGİLİ HERHANGİ BİR VARLIK
							TARAFINDAN SAĞLANAN HERHANGİ BİR TAVSİYE VEYA
							BİLGİYE GÜVENMEYİN. WEB SİTEMİZE ERIŞMEK İÇİN
							İNDİRME YAPMAK VEYA DİĞER SİTELERE ERİŞMEK KENDİ
							RİSKİNİZDEDİR. BİLGİSAYARINIZDA OLUŞABİLECEK
							HERHANGİ BİR ZARARDAN SADECE SİZ SORUMLUSUNUZ.
							İÇERİK, VERİ VE YAZILIM İNDİRME YOLUYLA ELDE EDİLEN
							VİRÜSL ER NEDENİYLE OLUŞAN VERİ KAYBI DURUMUNDA
							REKLAMSTORE SORUMLU DEĞİLDİR. REKLAMSTORE,
							REKLAMSTORE'UN ERİŞİLEBİLİR VEYA BAĞLANTILI OLDUĞU
							HERHANGİ BİR DİĞER TARAFIN ÜRÜNLERİ, VERİLERİ,
							HİZMETLERİ VEYA MATERYALLERİNDEN SORUMLU DEĞİLDİR.
							TANIMLADIĞINIZDAN DAHA ÖNEMLİSİ, WEB SİTEMİZDEKİ
							HERHANGİ BİR İÇERİĞİN HER ZAMAN TÜM İHTİYAÇLARINIZI
							KARŞILAYACAĞINI, REKLAMSTORE İÇERİĞİNİN ZAMANINDA,
							KESİNTİSİZ, HATASIZ VE GÜVENLİ OLACAĞINI VAAT
							EDEMEYİZ. TÜM KUSURLARIN TAMAMININ VEYA BİR KISMININ
							DÜZELTİLECEĞİNİ GARANTİ EDEMEYİZ. SİSTEM
							GECİKMELERİ, HATALAR, DOSYA BOZULMALARI, VERİ KAYBI
							VEYA HİZMET KESİNTİLERİ DAHİL OLMAK ÜZERE, YAŞANAN
							HERHANGİ BİR ZARARDAN REKLAMSTORE SORUMLU DEĞİLDİR.
							BU SINIRLAMALARIN UYGULANMADIĞI BİR EYALETTE
							YAŞIYORSANIZ, MUAF OLABİLİRSİNİZ.
						</span>

						<span className="smallTitle">
							ReklamStore Sorumluluk Sınırlamaları
						</span>

						<span className="text">
							REKLAMSTORE, BAĞLI KURULUŞLARI VEYA TARAFIN İLGİLİ
							OLDUĞU KİŞİ, REKLAMSTORE WEB SİTELERİNİN KULLANIMI
							VEYA KULLANIMININ ENGELLENMESİNDEN KAYNAKLANAN
							HERHANGİ BİR ZARARDAN VEYA BAĞLANTILI OLARAK ORTAYA
							ÇIKAN HERHANGİ BİR ZARARDAN SORUMLU TUTULABİLİR. BU,
							KULLANIM KAYBI, KÂR KAYBI, BİLGİ KAYBI VEYA DİĞER
							BİR KAYIPTAN KAYNAKLANAN ZARARLAR DAHİL OLMAK ÜZERE,
							HERHANGİ BİR TÜRDE ZARAR İÇERİR. DİKKAT EDİLMESİ
							GEREKEN HUSUS, BAZI YARGI YERLERİNİN SONUÇ ZARARLARI
							VEYA TESADÜFİ ZARARLAR İÇİN SORUMLULUĞU SINIRLAMA
							VEYA DIŞLAMA HUSUSUNDA YASAKLAMA SAHİP
							OLABİLECEĞİDİR. BU, S ORUMLULUK SINIRLAMASI
							ANLAŞMASININ BELİRLİ BİR BÖLÜMÜNÜN
							UYGULANMAYABİLECEĞİ ANLAMINA GELİR.
						</span>

						<span className="smallTitle">Hukuki Yükümlülükler</span>

						<span className="text">
							Bu Hizmet Şartlarında yer alan her şey, Türkiye
							Cumhuriyeti yasalarına uygun olarak düzenlenmiştir
							ve uygun olarak yürürlüğe konmuştur. Bu Hizmet
							Şartları ile ilgili yasal işlem başlatmanız
							durumunda, şikayetinizi Türkiye Cumhuriyeti Yüksek
							Mahkemesi veya İstanbul İl Mahkemesine yapmanız
							gerekmektedir.
						</span>

						<span className="smallTitle">
							ReklamStore Sonlandırma Kuralları
						</span>

						<span className="text">
							ReklamStore, hesabınızı ve/veya ReklamStore web
							sitesini kullanımınızı sona erdirebilir.
							ReklamStore, önceden bildirim yapmaksızın ve kendi
							takdirine bağlı olarak bunu yapma hakkına sahiptir.
							ReklamStore web sitelerine gelecekteki erişiminizi
							engellemek gerekebilir. Sizin için sunulan web
							sitesi kullanımınızı veya hizmetleri isteğimize
							bağlı olarak durdurma veya değiştirme seçeneğimiz
							vardır.
						</span>

						<span className="smallTitle">
							ReklamStore Hizmet Şartlarında İstisnalar
						</span>

						<span className="text">
							Bir şeyin yasa dışı olduğu tespit edildiğinde, bu
							Hizmet Şartlarının o kısmı ReklamStore
							politikasından çıkarılacak ve geçersiz hale
							gelecektir. Bununla birlikte, bu Hizmet Şartlarının
							diğer tüm yönleri geçerli olmaya devam edecek ve tüm
							kullanıcılar tarafından takip edilmeye devam
							edilmelidir. ReklamStore tarafından verilen bir
							koşulun feragat edilmesi veya bir hakkın tanınması,
							belgede yazılı olmadıkça kabul edilmeyecektir. Bir
							koşulu feragat etmek isteyen kişi, belgeyi kişisel
							olarak yazmalı ve imzalamalıdır.
						</span>
					</div>
				</div>
			</Modal>
			<Modal
				isOpen={privacyIsOpen}
				onRequestClose={closePrivacyModal}
				style={customStyles}
				contentLabel="prıvacy Modal"
			>
				<div className="conditionsModal">
					<div className="titleContainer">
						<span className="title">Gizlilik Politikası</span>
						<img
							src="https://joy-leads.s3.eu-central-1.amazonaws.com/rs/close-icon.svg"
							className="closeButton"
							onClick={closePrivacyModal}
						></img>
					</div>

					<div className="contentContainer">
						<div class="privacy-container svelte-1x0szyr">
							<ol class="svelte-1x0szyr">
								<p class="svelte-1x0szyr">
									ReklamStore, gizliliğinizi saygıyla karşılar
									ve kişisel verilerinizi koruma altına almayı
									taahhüt eder. Bu gizlilik politikası
									(“Politika”), web sitemizi ziyaret
									ettiğinizde kişisel verilerinizi nasıl
									topladığımız ve işlediğimiz hakkında sizi
									bilgilendirecektir. Bu politika hakkında
									herhangi bir sorunuz varsa, lütfen
									privacy@reklamstore.com adresiyle iletişime
									geçin.
								</p>{" "}
								<li class="svelte-1x0szyr bigTitle">
									KİM OLDUĞUMUZA DAİR BİLGİLER
								</li>{" "}
								<p class="svelte-1x0szyr">
									<b class="svelte-1x0szyr">
										BU POLİTİKANIN AMACI:
									</b>{" "}
									Bu Politika, ReklamStore’un web sitesini
									kullanmanız sırasında kişisel verilerinizi
									nasıl topladığını ve işlediğini, özellikle
									web sitemize üye olduğunuzda veya ürün veya
									hizmet satın aldığınızda sağladığınız
									verileri içerir.
								</p>{" "}
								<p class="svelte-1x0szyr">
									Verilerinizi nasıl ve neden kullandığımızı
									tam olarak anlamanız için bu Politikayı,
									diğer politika veya bildirimlerle birlikte
									okumanız önemlidir. Bu Politika, diğer
									bildirimlere ek olarak sunulur ve bunları
									geçersiz kılmak amaçlanmaz.
								</p>{" "}
								<p class="svelte-1x0szyr">
									<b class="svelte-1x0szyr">
										İLETİŞİM BİLGİLERİ:{" "}
									</b>
									Tam iletişim bilgilerimiz aşağıdaki gibidir:
								</p>{" "}
								<ul class="inner-ul svelte-1x0szyr">
									<li class="svelte-1x0szyr">
										Ticari unvan: RS Internet Pazarlama A.S.
									</li>{" "}
									<li class="svelte-1x0szyr">
										E-posta adresi: info@reklamstore.com
									</li>{" "}
									<li class="svelte-1x0szyr">
										Posta adresi: Yesilce Mahallesi, Diken
										Sokak, No: 4, Kat 4, ReklamStore,
										Kagithane, Istanbul, Turkey
									</li>{" "}
									<li class="svelte-1x0szyr">
										Telefon numarası: +90 (212) 284 44 49
									</li>
								</ul>{" "}
								<p class="svelte-1x0szyr">
									<b class="svelte-1x0szyr">
										VERİ SORUMLUSU:
									</b>{" "}
									ReklamStore, farklı yasal varlıklardan
									oluşur. Bu Politika, ReklamStore Grubu adına
									yayımlanmıştır, bu nedenle bu Politikada
									“ReklamStore”, “biz”, “bize” veya “bizim”
									ifadelerini kullandığımızda, verilerinizi
									işleyen ilgili ReklamStore Grubu şirketine
									atıfta bulunuyoruz.
								</p>{" "}
								<p class="svelte-1x0szyr">
									Verilerinizin nasıl işlendiği konusunda
									endişeleriniz varsa, veri koruma denetim
									otoritesine şikayette bulunma hakkınız
									vardır. Ancak, denetim otoritesine
									başvurmadan önce endişelerinizi ele alma
									şansına sahip olmamızı takdir ederiz, bu
									nedenle lütfen ilk olarak bizimle iletişime
									geçin.
								</p>{" "}
								<p class="svelte-1x0szyr">
									<b class="svelte-1x0szyr">
										POLİTİKANIN DEĞİŞMESİ VE DEĞİŞİKLİKLERİ
										BİZE BİLDİRME YÜKÜMLÜLÜĞÜNÜZ:
									</b>
									Bu sürüm en son Mayıs 2018'de
									güncellenmiştir. Sizinle ilgili tuttuğumuz
									kişisel verilerin doğru ve güncel olması
									önemlidir. Lütfen ilişkimiz sırasında
									kişisel verilerinizde değişiklik olduğunda
									bizi bilgilendirin.
								</p>{" "}
								<p class="svelte-1x0szyr">
									<b class="svelte-1x0szyr">
										ÜÇÜNCÜ TARAF BAĞLANTILARI:{" "}
									</b>
									Bu web sitesi, üçüncü taraf web siteleri,
									eklentiler ve uygulamalar içerebilir. Bu
									üçüncü taraf web sitelerini kontrol
									etmiyoruz. Web sitemizden ayrıldığınızda,
									ziyaret ettiğiniz her web sitesinin gizlilik
									bildirimini okumanızı öneririz.
								</p>{" "}
								<p class="svelte-1x0szyr">
									Lütfen dikkat edin, bu web sitesi çocuklar
									için tasarlanmamıştır ve bilerek çocuklarla
									ilgili veri toplamıyoruz.
								</p>{" "}
								<li class="svelte-1x0szyr bigTitle">
									SENİN HAKKINDA TOPLADIĞIMIZ VERİLER
								</li>{" "}
								<p class="svelte-1x0szyr">
									Kişisel veri veya kişisel bilgi, bir kişinin
									tanımlanabileceği herhangi bir bilgiyi ifade
									eder. Kimliği kaldırılmış veriler (anonim
									veriler) dışında kalan verileri içermez.
								</p>{" "}
								<p class="svelte-1x0szyr">
									Sizinle ilgili çeşitli kişisel veriler
									toplayabilir, kullanabilir, depolayabilir ve
									aktarabiliriz. Bunları aşağıdaki şekilde
									kategorize ettik:
								</p>{" "}
								<ul class="svelte-1x0szyr">
									<li class="svelte-1x0szyr">
										<b class="svelte-1x0szyr">Kimlik</b> ad,
										soyad, kullanıcı adı ve unvanı içerir.
									</li>{" "}
									<li class="svelte-1x0szyr">
										<b class="svelte-1x0szyr">İletişim</b>{" "}
										fatura ve teslimat adresi, e-posta
										adresi ve iletişim numaralarını içerir.
									</li>{" "}
									<li class="svelte-1x0szyr">
										Finansal, banka hesabı ve ödeme
										detaylarını içerir.
									</li>{" "}
									<li class="svelte-1x0szyr">
										<b class="svelte-1x0szyr">İşlem</b>,
										size yapılan ödemelerle ilgili detayları
										ve bize ait ürün ve hizmetlerin diğer
										detaylarını içerir.
									</li>{" "}
									<li class="svelte-1x0szyr">
										<b class="svelte-1x0szyr">Teknik</b>,
										internet protokolü (IP) adresi, giriş
										verileriniz, tarayıcı türü ve sürümü,
										saat dilimi ayarı ve konumu, tarayıcı
										eklenti türleri ve sürümleri, işletim
										sistemi ve platformu ve bu web sitesine
										erişmek için kullandığınız diğer
										cihazlardaki diğer teknolojileri içerir.
									</li>{" "}
									<li class="svelte-1x0szyr">
										<b class="svelte-1x0szyr">Kullanım</b>,
										web sitemizi, ürünlerimizi ve
										hizmetlerimizi nasıl kullandığınıza
										ilişkin bilgileri içerir.
									</li>{" "}
									<li class="svelte-1x0szyr">
										<b class="svelte-1x0szyr">
											Pazarlama ve İletişim
										</b>
										, bize ve üçüncü taraflara yönelik
										pazarlama tercihlerinizi ve iletişim
										tercihlerinizi içerir.
									</li>
								</ul>{" "}
								<p class="svelte-1x0szyr">
									Ayrıca, istatistiksel veya demografik
									veriler gibi{" "}
									<b class="svelte-1x0szyr">Toplu Veriler</b>{" "}
									de topluyor, kullanıyor ve paylaşıyoruz. Bu
									veriler doğrudan veya dolaylı olarak
									kimliğinizi ortaya çıkarmaz. Bununla
									birlikte, Toplu Verileri kişisel
									verilerinizle birleştirir veya bağlantı
									kurarsak ve sizi doğrudan veya dolaylı
									olarak tanımlayabilir hale getirirse,
									birleştirilmiş verileri bu gizlilik
									bildirimine uygun şekilde kullanacağız.
								</p>{" "}
								<p class="svelte-1x0szyr">
									Sizinle ilgili herhangi bir{" "}
									<b class="svelte-1x0szyr">
										Özel Kişisel Veri Kategorisi
									</b>{" "}
									toplamıyoruz (bu, ırk veya etnik kökeniniz,
									dini veya felsefi inançlarınız, cinsel
									yaşamınız, cinsel yöneliminiz, siyasi
									görüşleriniz, sendika üyeliğiniz, sağlık
									durumunuz ve genetik ve biyometrik
									verilerinizle ilgili ayrıntıları içerir).
									Ayrıca, ceza mahkumiyetleri ve suçlarla
									ilgili herhangi bir bilgi toplamıyoruz.
								</p>{" "}
								<p class="svelte-1x0szyr">
									Yasayla veya sizinle olan sözleşme şartları
									gereği kişisel veri toplamamız gerektiği
									durumlarda ve siz bu veriyi sağlamazsanız,
									sözleşmeyi yerine getiremeyebiliriz
									(örneğin, size mal veya hizmet sağlamak). Bu
									durumda, sizinle olan bir ürün veya hizmeti
									iptal etmek zorunda kalabiliriz, ancak sizi
									bilgilendireceğiz.
								</p>{" "}
								<li class="svelte-1x0szyr bigTitle">
									<b class="svelte-1x0szyr">
										KİŞİSEL VERİLERİNİZİ NASIL TOPLUYORUZ?
									</b>
								</li>{" "}
								<p class="svelte-1x0szyr">
									Verilerinizi aşağıdaki şekillerde
									topluyoruz:
								</p>{" "}
								<ul class="svelte-1x0szyr">
									<li class="svelte-1x0szyr">
										<b class="svelte-1x0szyr">
											Doğrudan etkileşimler:
										</b>{" "}
										Kimlik, İletişim ve Finansal
										Verilerinizi, formları doldurarak veya
										posta, telefon veya e-posta yoluyla bize
										yazışarak bize verebilirsiniz. Bu, (a)
										ürün veya hizmetlerimize başvuruda
										bulunduğunuzda; (b) web sitemizde bir
										hesap oluşturduğunuzda; (c) hizmetimize
										veya yayınlarımıza abone olduğunuzda;
										(d) size pazarlama malzemeleri
										gönderilmesini talep ettiğinizde veya
										(e) bize geribildirimde bulunduğunuzda
										sağladığınız kişisel verileri içerir.
									</li>{" "}
									<li class="svelte-1x0szyr">
										<b class="svelte-1x0szyr">
											Doğrudan etkileşimler:
										</b>{" "}
										Web sitemizle etkileşimde bulundukça,
										çerezler, sunucu günlükleri ve diğer
										benzer teknolojileri kullanarak
										ekipmanınızla ilgili Teknik Verileri
										otomatik olarak toplayabiliriz.
									</li>
								</ul>{" "}
								<li class="svelte-1x0szyr bigTitle">
									NE ZAMAN KİŞİSEL VERİLERİNİZİ KULLANIRIZ?
								</li>{" "}
								<p class="svelte-1x0szyr">
									Kanun bize izin verdiğinde, kişisel
									verilerinizi kullanırız. Genellikle, kişisel
									verilerinizi aşağıdaki durumlarda
									kullanırız:
								</p>{" "}
								<ul class="svelte-1x0szyr">
									<li class="svelte-1x0szyr">
										Sizimizle gireceğimiz veya girmiş
										olduğumuz sözleşmeyi yerine getirmemiz
										gerektiğinde.
									</li>{" "}
									<li class="svelte-1x0szyr">
										Kendi meşru çıkarlarımız (veya üçüncü
										bir tarafın meşru çıkarları) için
										gerekli olduğunda ve sizin çıkarlarınız
										ve temel haklarınız, bu çıkarları
										aşmadığında.
									</li>{" "}
									<li class="svelte-1x0szyr">
										Hukuki veya düzenleyici bir yükümlülüğü
										yerine getirmemiz gerektiğinde.
									</li>
								</ul>{" "}
								<p class="svelte-1x0szyr">
									Genel olarak, size üçüncü taraf doğrudan
									pazarlama iletişimlerini e-posta
									aracılığıyla göndermek dışında kişisel
									verilerinizi işlemek için onayınıza
									dayanmayız. Pazarlama için verilen onayı
									istediğiniz zaman privacy@reklamstore.com
									adresine başvurarak geri çekebilirsiniz.
								</p>{" "}
								<p class="svelte-1x0szyr">
									<b class="svelte-1x0szyr">
										Kişisel verilerinizi kullanacağımız
										amaçlar:
									</b>
								</p>{" "}
								<table class="svelte-1x0szyr">
									<tr class="svelte-1x0szyr">
										<th class="svelte-1x0szyr">
											Amaç/Etkinlik
										</th>{" "}
										<th class="svelte-1x0szyr">
											Veri Türü
										</th>{" "}
										<th class="svelte-1x0szyr">
											İşleme hukuki dayanağı, meşru çıkar
											dayanağı dahilinde
										</th>
									</tr>{" "}
									<tr class="svelte-1x0szyr">
										<td class="svelte-1x0szyr">
											Sizi yeni bir müşteri olarak
											kaydetmek
										</td>{" "}
										<td class="svelte-1x0szyr">
											Kimlik; İletişim
										</td>{" "}
										<td class="svelte-1x0szyr">
											Sizinle yapılan bir sözleşmenin
											yerine getirilmesi
										</td>
									</tr>{" "}
									<tr class="svelte-1x0szyr">
										<td class="svelte-1x0szyr">
											Siparişinizi işlemek ve teslim
											etmek, ödemeleri yönetmek, ücretleri
											ve masrafları idare etmek ve bize
											borçlu olan parayı tahsil etmek
										</td>{" "}
										<td class="svelte-1x0szyr">
											Kimlik; İletişim; Finansal; İşlem;
											Pazarlama ve İletişim
										</td>{" "}
										<td class="svelte-1x0szyr">
											Sizinle yapılan bir sözleşmenin
											yerine getirilmesi, bize olan
											borçların tahsili için meşru
											çıkarlarımızın gerektirmesi
										</td>
									</tr>{" "}
									<tr class="svelte-1x0szyr">
										<td class="svelte-1x0szyr">
											Sizinle olan ilişkimizi yönetmek,
											size şartlarımızda veya gizlilik
											politikamızda yapılan değişiklikleri
											bildirmek
										</td>{" "}
										<td class="svelte-1x0szyr">
											Kimlik; İletişim; Pazarlama ve
											İletişim
										</td>{" "}
										<td class="svelte-1x0szyr">
											Sizinle yapılan bir sözleşmenin
											yerine getirilmesi, hukuki bir
											yükümlülüğün yerine getirilmesi,
											kayıtlarımızı güncel tutma meşru
											çıkarlarım ızın gerektirmesi
										</td>
									</tr>{" "}
									<tr class="svelte-1x0szyr">
										<td class="svelte-1x0szyr">
											İşimizi ve bu web sitesini yönetmek
											ve korumak (arıza giderme, veri
											analizi, sistem bakımı, destek,
											raporlama ve verilerin
											barındırılması dahil)
										</td>{" "}
										<td class="svelte-1x0szyr">
											Kimlik; İletişim; Teknik
										</td>{" "}
										<td class="svelte-1x0szyr">
											İşimizi yürütmek için gerekli olan
											meşru çıkarlarımız (işletmemizi
											yürütmek, yönetim ve BT hizmetleri
											sağlamak, ağ güvenliğini sağlamak,
											dolandırıcılığı önlemek), hukuki bir
											yükümlülüğün yerine getirilmesi
										</td>
									</tr>{" "}
									<tr class="svelte-1x0szyr">
										<td class="svelte-1x0szyr">
											İlgili web sitesi içeriğini sağlamak
										</td>{" "}
										<td class="svelte-1x0szyr">
											Kimlik; İletişim; Kullanım;
											Pazarlama ve İletişim; Teknik
										</td>{" "}
										<td class="svelte-1x0szyr">
											Müşterilerin ürün/hizmetlerimizi
											nasıl kullandığını incelemek,
											bunları geliştirmek, işimizi
											büyütmek ve pazarlama stratejimizi
											bilgilendirmek için meşru
											çıkarlarımızın gerektirdiği
										</td>
									</tr>{" "}
									<tr class="svelte-1x0szyr">
										<td class="svelte-1x0szyr">
											Veri analitiği kullanarak web
											sitemizi, ürün/hizmetlerimizi,
											pazarlamamızı, müşteri
											ilişkilerimizi ve deneyimlerimizi
											iyileştirmek
										</td>{" "}
										<td class="svelte-1x0szyr">
											Teknik; Kullanım
										</td>{" "}
										<td class="svelte-1x0szyr">
											Ürünlerimiz ve hizmetlerimiz için
											müşteri türlerini belirlemek, web
											sitemizi güncel ve ilgili tutmak,
											işimizi geliştirmek ve pazarlama
											stratejimizi bilgilendirmek için
											meşru çıkarlarımızın gerektirdiği
										</td>
									</tr>
								</table>{" "}
								<ul class="svelte-1x0szyr">
									<li class="svelte-1x0szyr">
										Belirli kişisel veri kullanımlarıyla
										ilgili olarak, özellikle pazarlama ve
										reklamcılık konularında seçeneklere
										sahip olmanızı sağlamak istiyoruz.
										Pazarlama tercihlerinizi değiştirmek
										isterseniz lütfen bize başvurun.
									</li>{" "}
									<li class="svelte-1x0szyr">
										<b class="svelte-1x0szyr">
											Üçüncü Taraf Pazarlaması:
										</b>{" "}
										Pazarlama amaçları için ReklamStore
										şirketler grubu dışındaki herhangi bir
										şirketle kişisel verilerinizi
										paylaşmadan önce açık rıza alacağız.
									</li>{" "}
									<li class="svelte-1x0szyr">
										<b class="svelte-1x0szyr">Reddetme:</b>{" "}
										Herhangi bir zamanda
										privacy@reklamstore.com adresinden bize
										ulaşarak pazarlama mesajlarını almayı
										durdurmak isteyebilirsiniz. Pazarlama
										mesajlarını almamayı tercih etmeniz
										durumunda, ürün/hizmet satın alımı veya
										diğer işlemler sonucunda bize sağlanan
										kişisel verilere uygulanmayacaktır.
									</li>{" "}
									<li class="svelte-1x0szyr">
										Tarayıcınızı tüm çerezleri reddetmeye
										veya bazı çerezlere izin vermeye veya
										web siteleri çerezler ayarlandığında
										veya erişildiğinde sizi uyaracak şekilde
										ayarlayabilirsiniz. Çerezleri devre dışı
										bırakırsanız veya reddederseniz, bu web
										sitesinin bazı bölümleri erişilemez hale
										gelebilir veya düzgün çalışmayabilir.
										Kullanmış olduğumuz çerezler hakkında
										daha fazla bilgi için lütfen çerez
										politikamızı inceleyin.
									</li>{" "}
									<li class="svelte-1x0szyr">
										<b class="svelte-1x0szyr">
											Amacın Değiştirilmesi:
										</b>{" "}
										Kişisel verilerinizi, topladığımız
										amaçlar doğrultusunda kullanacağız.
										Ancak başka bir nedene ihtiyacımız varsa
										ve bu neden orijinal amacımızla uyumlu
										ise, yeni amaç için nasıl işlem
										yapıldığı konusunda bir açıklama
										isterseniz lütfen
										privacy@reklamstore.com adresine
										başvurun. Kişisel verilerinizi orijinal
										amacıyla uyumlu olmayan bir amaç için
										kullanmamız gerekiyorsa, sizi
										bilgilendireceğiz ve bunu yapmamıza izin
										veren hukuki dayanağı açıklayacağız.
									</li>
								</ul>{" "}
								<p class="svelte-1x0szyr">
									<b class="svelte-1x0szyr">
										Lütfen yasalar gereği belirli durumlarda
										kişisel verilerinizi bilgimiz veya
										onayınız olmaksızın işleyebileceğimizi
										unutmayın.
									</b>
								</p>{" "}
								<li class="svelte-1x0szyr bigTitle">
									<b class="svelte-1x0szyr">
										Kişisel Verilerinizi Paylaşımı
									</b>
								</li>{" "}
								<p class="svelte-1x0szyr">
									Tablo kısmında belirtilen amaçlar
									doğrultusunda kişisel verilerinizi aşağıda
									belirtilen taraflarla paylaşmamız gere
									kebilir.
								</p>{" "}
								<ul class="svelte-1x0szyr">
									<li class="svelte-1x0szyr">
										İçsel Üçüncü Taraflar: Ortak denetleyici
										veya işleme sorumlusu olarak hareket
										eden ReklamStore Grubu'ndaki diğer
										şirketler.
									</li>{" "}
									<li class="svelte-1x0szyr">
										Dışsal Üçüncü Taraflar, dahil olmak
										üzere:
										<ul class="svelte-1x0szyr">
											<li class="svelte-1x0szyr">
												Hizmet sağlayıcılarımız arasında
												yer alan avukatlar, bankacılar,
												denetçiler ve bize hizmet sunan
												sigortacılar dahil olmak üzere
												işleme sorumlusu veya ortak
												denetleyici olarak hareket eden
												profesyonel danışmanlar.
											</li>{" "}
											<li class="svelte-1x0szyr">
												Belirli durumlarda işleme
												faaliyetlerinin raporlanmasını
												gerektiren düzenleyiciler ve
												diğer yetkililer olarak işleme
												sorumlusu veya ortak denetleyici
												olarak hareket eden kişiler.
											</li>
										</ul>
									</li>{" "}
									<li class="svelte-1x0szyr">
										İşimizin bazı bölümlerini veya
										varlıklarını satmak, devretmek veya
										birleştirmek üzere seçebileceğimiz
										üçüncü taraflar. Alternatif olarak,
										başka işletmeleri satın almayı veya
										onlarla birleşmeyi düşünebiliriz.
										İşimizde bir değişiklik olursa, yeni
										sahipler kişisel verilerinizi bu
										gizlilik bildiriminde belirtildiği gibi
										kullanabilirler.
									</li>
								</ul>{" "}
								<p class="svelte-1x0szyr">
									Tüm üçüncü tarafların kişisel verilerinizin
									güvenliğine saygı duymasını ve yasalara
									uygun şekilde işlem yapmasını sağlıyoruz.
									Hizmet sağlayıcılarımızın yalnızca belirli
									amaçlar için kişisel verilerinizi
									işlemelerine ve talimatlarımıza uygun
									şekilde kullanmalarına izin veriyoruz.
								</p>{" "}
								<li class="svelte-1x0szyr bigTitle">
									<b class="svelte-1x0szyr">
										ULUSLARARASI VERİ TRANSFERLERİ
									</b>
								</li>{" "}
								<p class="svelte-1x0szyr">
									Kişisel verilerinizi ReklamStore Grubu
									içinde paylaşıyoruz. Bu, verilerinizin
									Avrupa Ekonomik Alanı (EEA) dışına
									aktarılmasını gerektirecektir. Ayrıca,
									birçok harici üçüncü tarafımız EEA dışında
									bulunduğu için kişisel verilerinizin
									işlenmesi EEA dışında veri aktarımını
									içerecektir.
								</p>{" "}
								<p class="svelte-1x0szyr">
									Kişisel verilerinizi EEA dışına aktardığımız
									her durumda, aşağıdaki güvencelerden en az
									birinin uygulandığından emin oluruz:
								</p>{" "}
								<ul class="svelte-1x0szyr">
									<li class="svelte-1x0szyr">
										Kişisel verilerinizi, Avrupa Komisyonu
										tarafından kişisel verilere uygun bir
										koruma düzeyi sağladığı tespit edilen
										ülkelere aktaracağız.
									</li>{" "}
									<li class="svelte-1x0szyr">
										Belirli hizmet sağlayıcılar
										kullanıyorsak, Avrupa Komisyonu
										tarafından onaylanan belirli
										sözleşmeleri kullanabiliriz. Bu
										sözleşmeler, kişisel verilere Avrupa'da
										olduğu gibi aynı korumayı sağlar.
									</li>{" "}
									<li class="svelte-1x0szyr">
										ABD merkezli sağlayıcılar kullanıyorsak,
										veriyi ABD ve Avrupa arasında paylaşılan
										kişisel verilere benzer koruma
										sağlamaları için gerekli olan Privacy
										Shield'a dahil olduklarında veri
										transferi yapabiliriz.
									</li>
								</ul>{" "}
								<p class="svelte-1x0szyr">
									Kişisel verilerinizi EEA dışına
									aktardığımızda kullanmış olduğumuz özel
									mekanizma hakkında daha fazla bilgi almak
									isterseniz, lütfen privacy@reklamstore.com
									adresinden bizimle iletişime geçin.
								</p>{" "}
								<li class="svelte-1x0szyr bigTitle">
									<b class="svelte-1x0szyr">ÇOCUKLAR</b>
								</li>{" "}
								<p class="svelte-1x0szyr">
									Çocuklar için 16 yaş ve altı sitelerden ve
									uygulamalardan ilgi temelli profiller
									oluşturmak veya kullanıcıların çıkarlara
									dayalı ilanlar sunmak amacıyla bilgi
									toplamaya izin vermiyoruz. Ancak, 16 yaş ve
									altı çocuklara yönelik sitelerden ve
									uygulamalardan, içerik veya sitenin veya
									uygulamanın türü gibi bağlamsal bilgilere
									dayalı olarak ilan sunmak gibi başka
									amaçlarla bilgi toplayabiliriz (örneğin, bir
									oyun uygulamasına oyun videosu için bir ilan
									sunmak).
								</p>{" "}
								<li class="svelte-1x0szyr bigTitle">
									<b class="svelte-1x0szyr">VERİ GÜVENLİĞİ</b>
								</li>{" "}
								<p class="svelte-1x0szyr">
									Kişisel verilerinizi, yasal, muhasebe veya
									raporlama gerekliliklerini karşılamak dahil
									olmak üzere, topladığımız amaçlar
									doğrultusunda gerekli olan süre kadar
									saklayacağız.
								</p>{" "}
								<p class="svelte-1x0szyr">
									Kişisel verilerinizin uygun saklama süresini
									belirlemek için, kişisel verilerin
									miktarını, doğasını ve hassasiyetini,
									yetkisiz kullanım veya açıklamadan
									kaynaklanan zarar riskini, kişisel verilerin
									işlenmesi için mevcut diğer yöntemlerle
									amaçlarımızı gerçekleştirebileceğimizi ve
									uygulanabilir yasal gereklilikleri dikkate
									alıyoruz.
								</p>{" "}
								<p class="svelte-1x0szyr">
									Bazı durumlarda kişisel verilerinizi
									anonimleştirebiliriz (sizinle
									ilişkilendirilemez hale getiririz) ve
									bunları araştırma veya istatistiksel
									amaçlarla süresiz olarak kullanabiliriz.
								</p>{" "}
								<li class="svelte-1x0szyr bigTitle">
									<b class="svelte-1x0szyr">
										HUKUKİ HAKLARINIZ
									</b>
								</li>{" "}
								<p class="svelte-1x0szyr">
									Kişisel verilerinizle ilgili olarak veri
									koruma yasaları kapsamında haklarınız
									bulunmaktadır.
								</p>{" "}
								<ul class="svelte-1x0szyr">
									<li class="svelte-1x0szyr">
										<b class="svelte-1x0szyr">
											Kişisel verilerinize erişim
											talebinde bulunma:
										</b>{" "}
										Böylelikle, sizin hakkınızda tuttuğumuz
										kişisel verilerin bir kopyasını alabilir
										ve bunların yasal olarak işlenip
										işlenmediğini kontrol edebilirsiniz.
									</li>{" "}
									<li class="svelte-1x0szyr">
										<b class="svelte-1x0szyr">
											Kişisel verilerinizin düzeltilmesini
											talep etme:
										</b>{" "}
										Böylelikle, hakkınızda tuttuğumuz eksik
										veya yanlış verilerin düzeltilmesini
										sağlayabilirsiniz.
									</li>{" "}
									<li class="svelte-1x0szyr">
										<b class="svelte-1x0szyr">
											Kişisel verilerinizin silinmesini
											talep etme:
										</b>{" "}
										Böylelikle, işlenmeye devam etmemiz için
										iyi bir neden olmadığında kişisel
										verilerinizi silmemizi veya kaldırmamızı
										isteyebilirsiniz.
									</li>{" "}
									<li class="svelte-1x0szyr">
										<b class="svelte-1x0szyr">
											Kişisel verilerinizin işlenmesine
											itiraz etme:
										</b>{" "}
										Kanuni bir çıkar (veya üçüncü bir
										tarafın çıkarı) dayandığımız durumlarda
										ve durumunuzla ilgili olarak temel hak
										ve özgürlüklerinizi etkilediğini
										düşündüğünüz bir durum söz konusu
										olduğunda itiraz etmek isteyebilirsiniz.
										Ayrıca, kişisel verilerinizi doğrudan
										pazarlama amaçlarıyla işlediğimiz
										durumlarda da itiraz hakkına sahipsiniz.
									</li>{" "}
									<li class="svelte-1x0szyr">
										<b class="svelte-1x0szyr">
											Kişisel verilerinizin işlenmesinin
											kısıtlanmasını talep etme:
										</b>{" "}
										Böylelikle, aşağıdaki senaryolarda
										kişisel verilerinizin işlenmesini askıya
										almamızı isteyebilirsiniz: (a) verinin
										doğruluğunu sağlamamızı istiyorsanız;
										(b) verinin kullanımımızın hukuka aykırı
										olduğu halde silinmesini istemiyors
										anız; (c) veriyi, artık gereksinim
										duymamıza rağmen, hala elde tutmamızı
										gerektiren, hukuki talepleri oluşturmak,
										kullanmak veya savunmak için ihtiyacınız
										varsa; veya (d) veri kullanımımıza
										itiraz ettiniz, ancak kullanmak için
										üstün haklı gerekçelerimizin olup
										olmadığını doğrulamamız gerekiyor.
									</li>{" "}
									<li class="svelte-1x0szyr">
										<b class="svelte-1x0szyr">
											Kişisel verilerinizin aktarılmasını
											talep etme:
										</b>{" "}
										Size veya üçüncü bir tarafa. Kişisel
										verilerinizi, yaygın olarak kullanılan,
										yapılandırılmış, makine tarafından
										okunabilir bir formatta size veya
										seçtiğiniz üçüncü tarafa sağlayacağız.
									</li>{" "}
									<li class="svelte-1x0szyr">
										<b class="svelte-1x0szyr">
											Onayı geri çekme hakkı:
										</b>{" "}
										Kişisel verilerin işlenmesi için
										onayımıza dayandığımız durumlarda.
									</li>
								</ul>{" "}
								<p class="svelte-1x0szyr">
									Yukarıda belirtilen haklardan herhangi
									birini kullanmak isterseniz, lütfen
									privacy@reklamstore.com adresinden bize
									ulaşın.
								</p>{" "}
								<p class="svelte-1x0szyr">
									Aşağıdaki linklerden herhangi birine
									tıklayarak istediğiniz zaman abonelikten
									çıkabilirsiniz.
								</p>{" "}
								<p class="svelte-1x0szyr">
									<a
										href="https://dsp.reklamstore.com/privacy-policy/"
										class="svelte-1x0szyr"
									>
										DSP Gizlilik Politikası
									</a>
								</p>{" "}
								<p class="svelte-1x0szyr">
									<a
										href="http://dmp.reklamstore.com/privacy-policy/"
										class="svelte-1x0szyr"
									>
										SSP ve DMP Gizlilik Politikası
									</a>
								</p>{" "}
								<li class="svelte-1x0szyr bigTitle">
									<b class="svelte-1x0szyr">
										BİZİMLE İLETİŞİME GEÇİN
									</b>
								</li>{" "}
								<p class="svelte-1x0szyr">
									Kişisel bilgilerinizle ilgili herhangi bir
									talebiniz veya bu Gizlilik Politikası ile
									ilgili herhangi bir sorunuz varsa, lütfen
									Web sitemiz üzerinden veya
									privacy@reklamstore.com adresi aracılığıyla
									bizimle iletişime geçmekten çekinmeyin.
								</p>{" "}
								<ul class="svelte-1x0szyr">
									<li class="svelte-1x0szyr">
										<b class="svelte-1x0szyr">
											Genellikle ücret talep edilmez.
										</b>{" "}
										Kişisel verilerinize erişmek için (veya
										diğer haklarınızı kullanmak için) bir
										ücret ödemeniz gerekmez. Bununla
										birlikte, talebiniz açıkça temelsiz,
										tekrarlayan veya aşırı ise makul bir
										ücret talep edebiliriz. Aksi takdirde,
										bu durumlarda talebinize uymayı
										reddedebiliriz.
									</li>{" "}
									<li class="svelte-1x0szyr">
										<b class="svelte-1x0szyr">
											Bize vermeniz gereken bilgiler.{" "}
										</b>
										Kimliğinizi doğrulamamıza ve kişisel
										verilere erişim hakkınızı (veya diğer
										haklarınızı kullanma hakkınızı)
										sağlamamıza yardımcı olmak için size
										özel bilgiler talep edebiliriz. Bu,
										kişisel verilerinizi yetkisiz ki şilerin
										erişmesini önlemeye yardımcı olur ve
										kişisel verilerinizi korumak için mümkün
										olan tüm önlemleri alırız.
									</li>{" "}
									<li class="svelte-1x0szyr">
										<b class="svelte-1x0szyr">
											Yasal olarak haklarınızı koruma
											hakkınız.
										</b>{" "}
										Daha fazla bilgi talep etme, kişisel
										verilerinizi düzeltme, silme veya
										sınırlama talebinde bulunma veya kişisel
										verilerinizi başka birine (veya size)
										aktarma hakkına sahipsiniz. Yetkililerle
										başvurabileceğiniz kişisel verilerinizin
										yanıt vermediğimiz bir talebiniz
										olduğunda size yardımcı olmaktan
										mutluluk duyarız. Yetkililere başvurmak
										için, sakin olduğunuz yerdeki veri
										koruma yetkilisiyle iletişim kurmanızı
										öneririz.
									</li>{" "}
									<li class="svelte-1x0szyr">
										<b class="svelte-1x0szyr">
											Haklarınıza saygı duyarız.
										</b>{" "}
										Kişisel verileriniz üzerinde kontrol
										sahibi olarak, veri koruma yasaları
										kapsamında sahip olduğunuz hakları
										kullanmanızı sağlamaktan mutluluk
										duyarız. Bize başvurduğunuzda
										taleplerinizi zamanında ve dürüst bir
										şekilde ele alacağımıza ve
										yanıtlayacağımıza söz veriyoruz. Eğer
										verilerinizi işleyen bir veri sorumlusu
										olarak sizi rahatsız ettiğimize
										inanıyorsanız, ayrıca yetkililere
										şikayette bulunma hakkınız olduğunu
										unutmayın.
									</li>
								</ul>{" "}
								<p class="svelte-1x0szyr">
									Bu Gizlilik Politikasının en son ne zaman
									güncellendiğini ve hangi değişikliklerin
									yapıldığını belirtmek için sayfanın en
									üstünde tarihini güncelleyeceğiz. Gizlilik
									Politikasının önemli değişiklikler yapması
									durumunda, değişiklikleri yayınlamadan önce
									size bildirimde bulunabiliriz.
								</p>{" "}
								<p class="svelte-1x0szyr">
									Bu Gizlilik Politikası hakkında herhangi bir
									sorunuz veya endişeniz varsa, lütfen
									privacy@reklamstore.com adresinden bize
									ulaşmaktan çekinmeyin.
								</p>{" "}
								<ul class="svelte-1x0szyr">
									<li class="svelte-1x0szyr">
										<b class="svelte-1x0szyr">
											Genellikle ücret talep edilmez.
										</b>{" "}
										Kişisel verilerinize erişmek için (veya
										diğer haklarınızı kullanmak için) bir
										ücret ödemeniz gerekmez. Ancak,
										talebiniz açıkça temelsiz, tekrarlayan
										veya aşırı ise makul bir ücret talep
										edebiliriz. Aksi takdirde, bu durumlarda
										talebinize uymayı reddedebiliriz.
									</li>{" "}
									<li class="svelte-1x0szyr">
										<b class="svelte-1x0szyr">
											Bize vermeniz gereken bilgiler.
										</b>{" "}
										Kişisel verilerinize erişiminizi (veya
										diğer haklarınızı kullanma hakkınızı)
										doğrulamak ve sağlamak için sizden
										belirli bilgiler talep etmemiz
										gerekebilir. Bu, kişisel verilerinizi
										alması gerekmeyen bir kişiye ifşa
										etmemek için bir güvenlik önlemidir.
										Talebinize ilişkin olarak size daha
										fazla bilgi talep etmek için de sizinle
										iletişim kurabiliriz ve yanıt sürecimizi
										hızlandırmak için yardımcı
										olabilirsiniz.
									</li>{" "}
									<li class="svelte-1x0szyr">
										<b class="svelte-1x0szyr">
											Yanıt verme süresi.
										</b>{" "}
										Tüm meşru taleplere mümkün olan en kısa
										sürede yanıt vermeye çalışıyoruz. Ancak
										talebiniz özellikle karmaşık ise veya
										birçok talepte bulunduysanız bir aydan
										daha uzun sürebilir. Bu durumda sizi
										bilgilendirerek sizi güncel tutacağız.
									</li>
								</ul>
							</ol>
						</div>
					</div>
				</div>
			</Modal>
			<footer>
				<div className="logoContainer">
					<img src="https://joy-leads.s3.eu-central-1.amazonaws.com/rs/footer-icon.svg" />
				</div>
				<div className="contractsContainer">
					<a onClick={openConditionsModal}>Şartlar ve Koşullar</a>
					<a onClick={openPrivacyModal}>Gizlilik</a>
				</div>
			</footer>
		</div>
	);
}
