export const offers = [
	{
		id: 1,
		code: "Cocobody",
		title: "Coco Body Oil ile mükemmel bronzluk",
		content:
			"Etkisi kanıtlanmış formülüyle güneş ışınlarının zararlı etkilerinden korunarak her mevsim sağlıklı bronzluk.",
		imageSrc:
			"https://joy-leads.s3.eu-central-1.amazonaws.com/rs/cocobody1.png",
		gender: 0,
		condition: {
			expression: "1:1",
		},
		page_name: "cocobody",
		aff_id: "34610",
	},
	{
		id: 2,
		code: "Bombsizeultra",
		title: "Bomb Size Ultra ile mükemmel göğüsler",
		content:
			"Estetik ameliyat, ilaç veya başka bir ürüne ihtiyaç duymadan hayalinizdeki göğüslere kavuşmanız mümkün!",
		imageSrc:
			"https://joy-leads.s3.eu-central-1.amazonaws.com/rs/bombsizeultra1.png",
		gender: 0,
		condition: {
			expression: "1:1",
		},
		page_name: "bombsizeultra",
		aff_id: "34610",
	},
	{
		id: 3,
		code: "Ketoburn",
		title: "Fazla yağlardan kurtulmak artık hayal değil!",
		gender: 1,
		content:
			"Hem hipertansiyonun hem de bağışıklık sistemi zayıflığının temel sebebi yağlardır. Daha sağlıklı yarınlar için bugünden adım atın.",
		imageSrc:
			"https://joy-leads.s3.eu-central-1.amazonaws.com/rs/ketoburn1.png",
		page_name: "ketoburn",
		aff_id: "34610",
	},
	{
		id: 4,
		code: "Diabacore",
		title: "DiabaCore ile diyabete bitkisel çözüm",
		gender: 1,
		content:
			"Sağlığınızı korumak ve diyabet hastalığının etkilerini en az seviyede hissetmek için 100% doğal içerikli bitkisel tabletler",
		imageSrc:
			"https://joy-leads.s3.eu-central-1.amazonaws.com/rs/diabacore1.png",
		page_name: "diabacore",
		aff_id: "34610",
	},
	{
		id: 5,
		code: "HealthyHeart",
		title: "Healthy Heart ile yüksek kan basıncına elveda",
		gender: 1,
		content:
			"İlk kullanımdan itibaren fark edilir sonuçlar: Hastalığın sebebini hedef alarak felç ve damar tıkanıklığı riskini sıfıra indirir!",
		imageSrc:
			"https://joy-leads.s3.eu-central-1.amazonaws.com/rs/healthyheart1.png",
		page_name: "healthyheart",
		aff_id: "34610",
	},
	{
		id: 6,
		code: "Collagen",
		gender: 1,
		title: "Kollagen Intensiv ile kırışıklıklara son!",
		content:
			"Kırışıklık ve sarkma başta olmak üzere bütün cilt sorunlarınızın cevabı burada - Doğal ve bitkisel formülle kolay ve hızlı çözüm!",
		imageSrc:
			"https://joy-leads.s3.eu-central-1.amazonaws.com/rs/collagen1.png",
		page_name: "kollagen",
		aff_id: "34610",
	},
	/* {
		id: 7,
		code: "JointFlex",
		title: "Artık eklemleriniz gençliğinizdeki gibi olabilir!",
		gender: 1,
		content:
			"Mucizeler her yaşta gerçekleşebilir! Eklem tedavisiyle gençliğinizdeki hareket kabiliyetinize yeniden kavuşun.",
		imageSrc:
			"https://joy-leads.s3.eu-central-1.amazonaws.com/rs/jointflex1.png",
	}, */
];
