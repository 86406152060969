import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { Link, useNavigate } from "react-router-dom";
import ShareIcon from "../Assets/ios-share.svg";
import "./FinalPage.scss";

const CompletedPage = ({
	successModalOpen,
	setSuccessModalOpen,
	selectedOffer,
}) => {
	function closeSuccessModal() {
		setSuccessModalOpen(false);
		navigate("/");
	}

	function referAFriend() {
		const shareURL = window.location.href + "?utm_source=raf";
		if (navigator.share) {
			navigator
				.share({
					title: "İyi Yaşam Analizim'e Gel!",
					text: "Kaliteli yaşam yolculuğun için sen de bu testi doldur.",
					url: shareURL,
				})
				.then(() => console.log("Başarılı Paylaşım"))
				.catch((error) => console.log("Paylaşım Hatası", error));
		} else {
			console.log("navigator.share desteklenmiyor.");
		}
	}

	const navigate = useNavigate();
	const customStyles = {
		content: {
			top: "50%",
			left: "50%",
			right: "auto",
			bottom: "auto",
			marginRight: "-50%",
			borderRadius: "8px",
			width: "40%",
			transform: "translate(-50%, -50%)",
		},
	};

	const isSuccess = localStorage.getItem("mailSentStatus") === 'true';
	const modalTitle =
		isSuccess ? "İşlem Başarılı" : "İşlem Başarısız";

	return (
		<div className="completedPage">
			<Modal
				isOpen={successModalOpen}
				onRequestClose={closeSuccessModal}
				style={customStyles}
				contentLabel="İşlem Başarılı"
			>
				<div className="conditionsModal successModal">
					<div className="titleContainer">
						<span className="title">{modalTitle}</span>
						<img
							src="https://joy-leads.s3.eu-central-1.amazonaws.com/rs/close-icon.svg"
							className="closeButton"
							onClick={closeSuccessModal}
						></img>
					</div>
					<div className="contentContainer successModal">
						{isSuccess && (
							<>
								<img
									src="https://joy-leads.s3.eu-central-1.amazonaws.com/rs/check.png"
									className="conditionsModal-modalIcon"
								/>
								<h3>
									<b>İşlem Başarılı!</b>
								</h3>
								<p>Sonuçlar e-posta adresinize gelecektir.</p>

								{selectedOffer && selectedOffer.code && (
									<p style={{ textAlign: "center" }}>
										{selectedOffer.code} ürünü ile
										ilgilendiğiniz için teşekkürler, çağrı
										merkezimiz sizi kısa sürede arayacaktır.
									</p>
								)}
							</>
						)}
						{!isSuccess && (
							<>
								<img
									src="https://joy-leads.s3.eu-central-1.amazonaws.com/rs/cross.png"
									className="conditionsModal-modalIcon"
								/>
								<h3>
									<b>İşlem Başarısız!</b>
								</h3>
								<p>Lütfen daha sonra tekrar deneyin.</p>
							</>
						)}
						<button
							onClick={referAFriend}
							className="referAFriendButton"
						>
							<img className="shareIcon" src={ShareIcon} />
							<span className="shareText">Paylaş</span>
						</button>
					</div>
				</div>
			</Modal>
		</div>
	);
};

export default CompletedPage;
